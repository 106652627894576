.cus_details{
    /* border-style: solid; */
    /* border-width: 2px; */
    border-radius: 10px;
    /* padding: 10px; */
    /* border-color: #00a14b; */
}

.order_div{
    margin-left: -100px;
}

@media (max-width: 1320px) {
    .order_div{
        margin-left: -40px;
    }
} 
.order_table{
    table-layout: fixed;
    height: 400px;
}

.btn-profile{
    background-color: #00b060 !important;
    color: white !important;
    border: none !important;
    margin-left: 30px;
    width: 100%;
}

.thead-green{
    background-color: #00b060 !important;
    color: white !important;
}

.btn-order{
    background-color: #00b060 !important;
    color: white !important;
    border: none !important;
    width: 50%;
}

.right_radius{
    border-top-right-radius: 20px;
}
.left_radius{
    border-top-left-radius: 20px;
}

table{
    border: none;
}

th{
    border: none;
}

.mg-20{
    padding: 20px;
}

.profile-info {
    font-weight: bold;
    font-size: 13px;
    /* margin-left: 15px; */
}
.profile-value {
    font-size: 12px;
    margin-left: -10px;
}
.profile-header {
    margin-left: 15px;
    color: #00a14b;
}
.profile-sidebar {
    background-color: whitesmoke;
    margin-left: 10px;
    -webkit-box-shadow: 3px 3px 5px 4px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    3px 3px 5px 4px #ccc;  /* Firefox 3.5 - 3.6 */
    box-shadow:         3px 3px 5px 4px #ccc;
}
.hr-line {
    margin-top: -10px;
    background-color: #00a14b;
    /* height: 1px; */
}
#backdrop {
    z-index: 10;
    color: whitesmoke;
    /* z-index: zIndex.drawer + 1;
    color: '#fff'; */
  }
/* 
@media (max-width: 1320px) {
    #cus-orders {
        width:"200px";
        margin-left: auto;
    }
} */