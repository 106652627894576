.btn-theme{
    background-color: #00b060 !important;
    border: none !important;
    color: white !important;
}

.info-btn-theme {
    background-color: rgb(160, 160, 160) !important;
    border: none !important;
    color: white !important;
}

.table-data {
    width: 200px;
}

.table-header {
    padding: 10px;
    font-size: 15px;
}

.editForm {
    background-color: whitesmoke;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    border-radius: 20px;
    box-shadow: 2px 2px 5px 4px #ccc;
}

.editLine {
    background-color: "#00a14b";
}

.header {
    font-size: 40px;
    line-height: 70px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #00b060;
    /* background-color: white; */
    width: 100%;
    text-align: center;
}

.header-sub {
    font-size: 25px;
    line-height: 70px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #00b060;
    /* background-color: white; */
    width: 100%;
    text-align: center;
}