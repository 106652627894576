.navbar_logo{
    width: auto;
    height: 60px;
}

.nav-item :hover{
    background-color: #00a14b;
    color: white !important;
}

.nav-item-link {
    background-color: #f8f9fa !important;
    color: black !important;
  }

li a{
    border-radius: 10px;
    border: 1px solid #00a14b;
    margin: 4px;
}

li a:hover{
    color: white !important;
    background-color: #00a14b !important;
    
}

.navdiv{
    border-bottom: medium !important;
    border-color: #00a14b;
}

tbody tr:hover{
    background-color: #bebebe !important;
    color: white !important;
}

.nav-link{
    text-align: center;
    width: 100px;
}

.navbar_header {
    /* background-color: whitesmoke; */
    /* box-shadow:         3px 3px 5px 4px #ccc; */
    box-shadow:         2px 2px 5px 2px #ccc; 
}
#customers-nav {
    background-color: #00a14b;
}
#customers-nav-text {
    color: white;
}