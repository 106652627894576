.btn-theme-form{
    background-color: #00B060 !important;
    border: none !important;
    color: white !important;
    width: 50%;
}

.btn-secondary-form{
    color: white !important;
    width: 50%;
}
.btn-secondary-form:hover{
    width: 50%;
    text-decoration: none;
    color: white !important;

}
.btn-theme-form:hover{
    width: 55%;
}

.formLabel{
    margin-top: 15%;
}

table ,th{
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }

  .table-shadow {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }

td{
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

#field_title{
    font-weight: 700;
    font-size: 16px;
}

.registerModal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  .registerModal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    margin-top: 20px;
  }
  
  .registerModalText {
    color: green;
    font-size: 18px;
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    width: auto;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

.create-customer-field {
  background-color: rgba(255, 255, 255, 0.719);
}